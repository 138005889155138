<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Inventario</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">LISTA DE MOVIMIENTOS</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem">
        <strong>LISTA DE MOVIMIENTOS DE INVENTARIO</strong>
      </span>
    </div>
    <div class="col-12 p-fondo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span><strong>SUCURSAL: </strong></span>
          <Dropdown
            v-model="sucursalSeleccionada"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>C&Oacute;D MOVIMIENTO: </strong></span>
          <InputText
            v-model="cod_movimiento"
            placeholder="Cod. Mov"
            :class="'text-right'"
            @keyup.enter="BuscarFiltro"
          />
        </div>
        <div class="field col-12 md:col-4">
          <span
            ><strong>TIPO MOVIMIENTO: </strong></span
          >
          <Dropdown
            v-model="tipo_movimientoSelect"
            :options="tipos_movimientos"
            optionLabel="nombre"
            optionValue="id"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>USUARIOS</strong></span>
          <Dropdown
            v-model="usuarioSeleccionado"
            :options="usuarios"
            optionLabel="name"
            optionValue="id"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>DESDE: </strong></span>
          <Calendar
            v-model="fecha_mov_desde"
            dateFormat="dd/mm/yy"
            :showIcon="true"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>HASTA: </strong></span>
          <Calendar
            v-model="fecha_mov_hasta"
            dateFormat="dd/mm/yy"
            :showIcon="true"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="APLICAR FILTROS"
            icon="pi pi-search"
            class="p-button-info"
            v-tooltip.top="'Buscar'"
            :loading="enviando"
            :disabled="enviando"
            @click="BuscarFiltro"
          />
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dt_movimientos"
        key="id"
        :value="movimientos"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
        :rows="50"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Movimientos"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Movimientos..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'Inventario Movimientos' in auth.user.permissions"
                label="DESCARGAR PDF"
                icon="pi pi-file-pdf"
                class="p-button-danger mr-1"
                v-tooltip.top="'Imprimir Reporte PDF'"
                :loading="generandoPDF"
                :disabled="generandoPDF"
                @click="generarPDF"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <Button
                v-if="'Inventario Movimientos' in auth.user.permissions"
                label="EXPORTAR EXCEL"
                icon="pi pi-file-excel"
                class="p-button-success"
                v-tooltip.top="'Imprimir Reporte Excel'"
                :loading="exportando"
                @click="exportCompras"
                disabled
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column field="id" header="CÓD" :sortable="true"> </Column>
        <Column field="nombre_usuario" header="USUARIO"> </Column>
        <Column field="tipo_movimiento" header="TIPO MOVIMIENTO"> </Column>
        <Column field="observacion" header="OBSERVACION"> </Column>
        <Column field="fecha" header="F. MOVIMIENTO"> </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised p-button-rounded p-button-info"
              v-tooltip.top="'Ver Acciones'"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      v-model:visible="tipo_impresionModal"
      :style="{ width: '450px' }"
      header="Tipo de Impresión"
      :modal="true"
      class="p-fluid"
    >
      <div class="col-12">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <label for="tipo_impresion"
              ><strong>SELECCIONE EL TIPO DE IMPRESI&Oacute;N: </strong></label
            >
            <Dropdown
              v-model="tipo_impresionSelect"
              :options="tipos_impresion"
              optionLabel="nombre"
              optionValue="id"
            />
          </div>
          <div class="field col-12 md:col-12 mt-4">
            <div class="p-inputgroup flex justify-content-end">
              <Button
                label="CANCELAR"
                icon="pi pi-times"
                class="p-button-danger mr-2 p-button-lg"
                @click="tipo_impresionModal = false"
              />
              <Button
                label="IMPRIMIR"
                icon="pi pi-print"
                class="p-button-success p-button-lg"
                @click="imprimirMovimiento"
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import SucursalService from "@/service/SucursalService";
import UserService from "@/service/UserService";
import TipoMovimientos from "@/service/TipoMovimientos";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      sucursales: [{ id: 0, nombre: "TODAS" }],
      sucursalSeleccionada: 0,
      tipos_movimientos: [{ id: 0, nombre: "TODOS" }],
      tipo_movimientoSelect: 0,
      usuarios: [{ id: 0, name: "TODOS" }],
      usuarioSeleccionado: 0,
      enviando: false,
      fecha_mov_desde: null,
      fecha_mov_hasta: null,
      cod_movimiento: null,
      movimientos: [],
      generandoPDF: false,
      exportando: false,
      buscar: {
        global: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      tipo_impresionSelect: 1, // 1=Carta, 2=Ticket
      tipo_impresionModal: false,
      tipos_impresion: [
        { id: 1, nombre: "CARTA" },
        { id: 2, nombre: "TICKET" },
      ],
      id_mov: null,
    };
  },
  sucursalService: null,
  tipoMovimientoService: null,
  UserService: null,
  auth: null,
  created() {
    this.sucursalService = new SucursalService();
    this.UserService = new UserService();
    this.tipoMovimientoService = new TipoMovimientos();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarUsers();
    this.cargarTiposMovimientos();
  },
  methods: {
    acciones(data) {
      return [
        {
          label: "Ver Detalle",
          icon: "pi pi-fw pi-eye",
          disabled:
            "Inventario Movimientos" in this.auth.user.permissions
              ? false
              : true,
          to: `/mov_almacen/${data.id}/detalle`,
        },
        {
          label: "Imprimir",
          icon: "pi pi-print",
          disabled:
            "Inventario Movimientos" in this.auth.user.permissions
              ? false
              : true,
          /* command: () => {
            this.imprimirMovimiento(data.id);
          }, */
          command: () => {
            this.tipo_impresionModal = true;
            //le paso el id del movimiento
            this.id_mov = data.id;
          },
        },
      ];
    },
    imprimirMovimiento() {
      let datos = {
        tipo_impresion: this.tipo_impresionSelect,
        movimiento_id: this.id_mov,
      };
      this.tipoMovimientoService
        .imprimirMov(datos)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    generarPDF() {
      if (this.movimientos.length > 0) {
        this.generandoPDF = true;
        let datos = {
          sucursal_id: this.sucursalSeleccionada,
          usuario_id: this.usuarioSeleccionado,
          fecha_mov_desde: this.fecha_mov_desde,
          fecha_mov_hasta: this.fecha_mov_hasta,
          tipo_movimiento_id: this.tipo_movimientoSelect,
          cod_movimiento: this.cod_movimiento,
        };
        this.tipoMovimientoService.imprimirPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se generó el PDF correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    exportCompras() {
      if (this.movimientos.length > 0) {
        this.exportando = true;
        let datos = {
          sucursal_id: this.sucursalSeleccionada,
          usuario_id: this.usuarioSeleccionado,
          fecha_mov_desde: this.fecha_mov_desde,
          fecha_mov_hasta: this.fecha_mov_hasta,
          tipo_movimiento_id: this.tipo_movimientoSelect,
          cod_movimiento: this.cod_movimiento,
        };
        this.tipoMovimientoService.exportarExcel(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se ha generado el archivo correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "No hay datos para exportar",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.movimientos = [];
      this.enviando = true;
      this.cargarMovimientos();
    },
    cargarMovimientos() {
      let datos = {
        sucursal_id: this.sucursalSeleccionada,
        usuario_id: this.usuarioSeleccionado,
        fecha_mov_desde: this.fecha_mov_desde,
        fecha_mov_hasta: this.fecha_mov_hasta,
        tipo_movimiento_id: this.tipo_movimientoSelect,
        cod_movimiento: this.cod_movimiento,
      };
      this.tipoMovimientoService.filtrarTipoMov(datos).then((response) => {
        this.movimientos = response.movimientos;
        this.enviando = false;
      });
    },
    cargarTiposMovimientos() {
      this.tipoMovimientoService.getTipoMovimientosAll().then((response) => {
        response.forEach((tipo) => {
          this.tipos_movimientos.push(tipo);
        });
      });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarUsers() {
      this.UserService.getUsersAll().then((response) => {
        response.forEach((user) => {
          this.usuarios.push(user);
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 5px;
}
</style>
